<template>
  <v-dialog v-model="dialog" retain-focus max-width="800" @click:outside="cancel" @keydown.esc="cancel">
    <v-card ripple class="px-4 py-2">
      <v-form ref="form" v-model="valid" lazy-validation style="background-color: none;">
        <v-card-title class="headline justify-center"><v-icon class="mr-2 mb-1">mdi-shield-alert</v-icon>{{ title }}</v-card-title>
        <v-alert class="ma-2" type="info" outlined dense>{{ subtitle }}</v-alert>
        <v-textarea v-model="changes" :counter="120" :rules="[characterLimit]" class="mt-4 mx-2 mb-4" outlined label="Motivo" />
      </v-form>
      <v-card-actions class="pt-0">
        <v-btn class="mb-2 mr-2" block color="primary" outlined @click="save">Prosseguir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ''
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    title: 'MOTIVO DA ALTERAÇÃO (OPCIONAL)',
    subtitle: 'Alterações de propriedades críticas do cliente precisam de uma justificativa.',
    valid: false,
    stringRules: v => !!v || 'Campo obrigatório',
    characterLimit: v => (v || '').length <= 120 || 'Campo não pode ter mais que 120 caractéres'
  }),
  computed: {
    changes: {
      get() {
        return this.message
      },
      set(value) {
        this.$emit('update:message', value)
      }
    }
  },
  methods: {
    save() {
      if (!this.$refs.form.validate()) return this.$snackbar({ message: 'Falha ao validar campos', snackbarColor: '#F44336' })
      this.$emit('proceed')
      this.cancel()
    },
    cancel() {
      this.$emit('update:dialog', false)
      this.changes = ''
    }
  }
}
</script>
